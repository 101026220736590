<template>
  <div class="dropdown-collapse-container" :class="rootClassName">
    <div
      class="dropdown-collapse-container1 px-7 lg:px-0 pt-2"
      @click="toggleActive()"
    >
      <span class="dropdown-collapse-text font-medium leading-6">{{ title }}</span>
      <div class="flex flex-wrap space-x-4">
        <span v-if="variantLabel" class="text-xs text-right text-brown">{{variantLabel}}</span>
        <img
          :src="
            isActive
              ? '/playground_assets/chev-up.svg'
              : '/playground_assets/chev-down.svg'
          "
          :alt="image_alt"
          class="dropdown-collapse-image"
        />
      </div>
    </div>
    <div class="w-full flex overflow-x-auto px-7 lg:px-0 pb-4" v-if="isActive">
      <div
        class="select-variant"
        v-for="item in this.child"
        :key="item.id"
        @click="pilihVariant(item)"
      >
      <div v-if="item.is_label_only === true" class="wrap-button relative border border-white rounded-lg overflow-hidden h-16 w-28">
        <div v-if="variantId === item.id" class="flex flex-col justify-center h-full">
          <svg class="mx-auto relative z-20" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L9 17L4 12" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          </svg>
          <p class="mt-0 text-base font-medium text-white relative z-20">{{ item.name }}</p>
          <div class="absolute inset-0 w-full h-full opacity-60 z-10" style="background-color: #1F252C;"></div>
        </div>
        <div v-else class="flex flex-col justify-center h-full">
          <p class="mt-0 text-base font-medium">{{ item.name }}</p>
        </div>
      </div>
      <div v-else>
        <div class="wrap-image">
          <img :src="item.image.photo" class="variant-image" />
          <div class="check" v-if="variantId === item.id">
            <img src="/playground_assets/oke.svg" class="check-image" />
          </div>
        </div>
        <p class="w-28">{{ item.name }}</p>
      </div>
      </div>
    </div>
    <span v-if="!variantChoose && isActive" class="text-base text-red pb-4 px-7 lg:px-0">You have not selected this variant configuration</span>
  </div>
</template>

<script>
  export default {
    name: 'DropdownCollapse',
    data() {
      return {
        variantId: null,
        variantChoose: false,
        variantLabel: '',
      };
    },
    methods: {
      toggleActive() {
        // this.isActive = !this.isActive;
        this.updateToggle(this.title);
      },
      pilihVariant(item) {
        this.variantId = item.id;
        this.updateVariant({ name: item.name, title: this.title });
        this.variantChoose = true;
        this.variantLabel = item.name;
      },
    },
    props: {
      title: {
        type: String,
        default: 'Shell Color',
      },
      image_src: {
        type: String,
        default: '/playground_assets/chevron-down.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      rootClassName: String,
      child: Array,
      updateVariant: Function,
      updateToggle: Function,
      isActive: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped>
  .wrap-image {
    @apply relative w-20 h-20 mx-auto;
  }
  .check {
    @apply cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center rounded-full;
  }
  .variant-image {
    @apply rounded-full overflow-hidden h-20 w-20;
  }
  .select-variant {
    @apply cursor-pointer text-center mr-4;
  }
  .select-variant p {
    @apply font-sans text-base leading-5 font-medium text-[#1f252c] mt-2;
  }
  .dropdown-collapse-container {
    @apply w-full flex relative flex-col border-b border-[#aea79d] border-opacity-60;
  }
  .dropdown-collapse-container1 {
    @apply w-full cursor-pointer flex justify-between items-center pb-4;
  }
  .dropdown-collapse-text {
    @apply text-lg font-sans font-medium;
  }
  .dropdown-collapse-image {
    @apply w-4;
  }
</style>
