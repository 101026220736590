<template>
  <div class="buyer-review-container" v-bind:class="rootClassName">
    <div class="buyer-review-container-buyer">
      <div class="buyer-review-header">
        <div class="buyer-review-container1">
          <span class="buyer-review-text">{{ text }}</span>
          <span class="buyer-review-text1">{{ text1 }}</span>
        </div>
        <div class="buyer-review-container2">
          <img :alt="image_alt" :src="image_src" class="buyer-review-star-on" />
          <img
            :alt="image_alt1"
            :src="image_src1"
            class="buyer-review-star-on1"
          />
          <img
            :alt="image_alt11"
            :src="image_src11"
            class="buyer-review-star-on2"
          />
          <img
            :alt="image_alt111"
            :src="image_src111"
            class="buyer-review-star-on3"
          />
          <img
            :alt="image_alt2"
            :src="image_src111"
            class="buyer-review-star-off"
          />
        </div>
      </div>
      <div class="buyer-review-review-image">
        <img :alt="image_alt3" :src="image_src3" class="buyer-review-image" />
        <img :alt="image_alt31" :src="image_src31" class="buyer-review-image1" />
        <img
          :alt="image_alt311"
          :src="image_src311"
          class="buyer-review-image2"
        />
      </div>
      <span class="buyer-review-text-desc">{{ text2 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyerReview',
  props: {
    image_alt2: {
      type: String,
      default: '/playground_assets/review_img.png',
    },
    image_src31: {
      type: String,
      default: '/playground_assets/review_img.png',
    },
    rootClassName: String,
    image_alt111: {
      type: String,
      default: '/playground_assets/review_img.png',

    },
    image_src: {
      type: String,
      default: '/playground_assets/star-on.svg',
    },
    text: {
      type: String,
      default: 'Anita Rosalina',
    },
    text2: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mi amet, lobortis a tempus, cursus nunc eget sit. Odio volutpat nunc posuere fringilla faucibus sed vulputate. Amet, ultricies amet adipiscing et, viverra. Ut molestie a pellentesque.',
    },
    image_alt11: {
      type: String,
      default: 'image',
    },
    image_src2: {
      type: String,
      default: '/playground_assets/start-off.svg',
    },
    image_alt31: {
      type: String,
      default: '/playground_assets/review_img.png',
    },
    text1: {
      type: String,
      default: 'September 2021',
    },
    image_alt3: {
      type: String,
      default: 'image',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/star-on.svg',
    },
    image_alt311: {
      type: String,
      default: 'image',
    },
    image_src311: {
      type: String,
      default: '/playground_assets/review_img.png',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    image_src111: {
      type: String,
      default: '/playground_assets/star-on.svg',
    },
    image_src3: {
      type: String,
      default: '/playground_assets/review_img.png',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src11: {
      type: String,
      default: '/playground_assets/star-on.svg',
    },
  },
}
</script>

<style scoped>
.buyer-review-container {
  width: 46%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.buyer-review-container-buyer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-top: 1px solid #EAE3D9;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.buyer-review-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buyer-review-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.buyer-review-text {
  color: #1F252C;
  font-size: 18px;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
}
.buyer-review-text1 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.buyer-review-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.buyer-review-star-on {
  width: 24px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-star-on1 {
  width: 24px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-star-on2 {
  width: 24px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-star-on3 {
  width: 24px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-star-off {
  width: 24px;
  object-fit: cover;
}
.buyer-review-review-image {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.buyer-review-image {
  width: 74px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-image1 {
  width: 74px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-image2 {
  width: 74px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.buyer-review-text-desc {
  color: #1F252C;
  margin-top: var(--dl-space-space-unit);
  font-family: Montserrat;
  line-height: 1.7;
}



@media(max-width: 991px) {
  .buyer-review-header {
    align-items: flex-start;
  }
  .buyer-review-container{
    width: 100%;
  }
}
</style>
