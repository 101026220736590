<template>
  <div class="box-indent-container max-lg:shadow" :class="rootClassName">
    <div class="box-indent-container1">
      <div class="box-indent-container2">
        <span class="box-indent-text">{{ text1 }}</span>
        <span class="box-indent-text1">{{ text2 }}</span>
      </div>
      <div
        class="flex-1 flex gap-7 max-lg:mr-0 lg:mr-7 max-lg:w-full lg:w-auto"
      >
        <div class="box-indent-container3">
          <img
            @click="kurang"
            :src="image_src"
            :alt="image_alt"
            class="box-indent-image cursor-pointer"
          />
          <input
            type="text"
            v-model.number="jumlah"
            :disabled="disabled"
            :placeholder="textinput_placeholder"
            class="box-indent-textinput input"
          />
          <img
            @click="tambah"
            :src="image_src1"
            :alt="image_alt1"
            class="box-indent-image1 cursor-pointer"
          />
        </div>
        <div
          v-if="disabled"
          class="box-indent-btn-login flex-1"
          :class="{ 'box-indent-btn-login-disabled': disabled }"
        >
          <span class="box-indent-text2">
            {{ stock ? 'Add To Cart' : 'Indent' }}
          </span>
        </div>
        <template v-else>
          <button
            v-if="stock"
            type="button"
            :disabled="jumlah > stock"
            class="box-indent-btn-login flex-1"
            @click="addCart()"
          >
            Add To Cart
          </button>
          <button
            v-else
            type="button"
            class="box-indent-btn-login flex-1"
            @click="openIndent"
          >
            <span class="box-indent-text2">Indent</span>
          </button>
        </template>
      </div>
      <div class="box-indent-container4">
        <img
          :src="image_src2"
          :alt="image_alt2"
          class="box-indent-image2"
          @click="$emit('share')"
          @mouseenter="activateMenu"
        />
        <img
          :src="isWishlist ? image_src3_active : image_src3"
          :alt="image_alt3"
          class="box-indent-image3"
          @click="wishAction()"
        />
      </div>
      <div
        class="account-menu absolute right-20 bottom-36 md:right-0 md:ottom-3/4 border border-solid border-gray-300 bg-white flex flex-col gap-y-1 text-black"
        v-if="menu.active"
        @mouseleave="menu.active = false"
      >
        <div class="flex flex-col gap-y-4 p-4 text-[#1F252C]">
          <div class="flex align-center cursor-pointer">
            <share-network
              class="flex text-[#1F252C]"
              network="facebook"
              :url="url"
              :title="text1"
              :description="text1"
            >
              <img
                alt="image"
                src="/playground_assets/facebook.svg"
                class="mr-2 w-4 h-4"
              />
              <span class="text-sm">Facebook</span>
            </share-network>
          </div>
          <div class="flex align-center cursor-pointer">
            <share-network
              class="flex text-[#1F252C]"
              network="twitter"
              :url="url"
              :title="text1"
            >
              <img
                alt="image"
                src="/playground_assets/twitter.svg"
                class="mr-2 w-4 h-4"
              />
              <span class="text-sm">Twitter</span>
            </share-network>
          </div>
          <div class="flex align-center cursor-pointer">
            <share-network
              class="flex text-[#1F252C]"
              network="whatsapp"
              :url="url"
              :title="text1"
              :description="text1"
            >
              <img
                alt="image"
                src="/playground_assets/whatsapp.svg"
                class="mr-2 w-4 h-4"
              />
              <span class="text-sm">WhatsApp</span>
            </share-network>
          </div>
          <div class="flex align-center cursor-pointer" @click="copy">
            <img
              alt="image"
              src="/playground_assets/link.svg"
              class="mr-2 w-4 h-4"
            />
            <span class="text-sm">Copy URL</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Api from '../api';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'BoxIndent',
    data() {
      return {
        jumlah: 0,
        wishlist: [],
        alert: false,
        menu: {
          active: false,
          enter: false,
        },
      };
    },
    mounted() {
      this.getWishlist();
    },
    methods: {
      openIndent() {
        if (this.$store.state.isAuthenticated) {
          this.$emit('indent', {
            product_id: this.item.product_id,
            qty: this.jumlah,
          });
        } else {
          const redirectUrl = localStorage.setItem('redirect_url', window.location.pathname);
          this.$router.push('/login');
        }
      },
      activateMenu() {
        this.menu.active = true;
        setTimeout(() => {
          document.addEventListener('click', this.deactivateMenu);
        }, 200);
      },

      deactivateMenu() {
        this.menu.active = false;
        document.removeEventListener('click', this.deactivateMenu);
      },

      copy() {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied.');
      },

      async wishAction() {
        await axios.post(Api.wishlistUrl, {
          product_id: this.item.product.parent_id,
        });
        await this.getWishlist();
      },
      async getWishlist() {
        if (this.$store.state.isAuthenticated) {
          const content = await axios.get(
            Api.wishlistUrl + '?page=1&perpage=100'
          );
          let array = [];
          for (let index = 0; index < content.data.data.length; index++) {
            const element = content.data.data[index];
            array.push(element.product_id);
          }
          this.wishlist = array;
        }
      },
      tambah() {
        if (!this.disabled) {
          this.jumlah = this.jumlah + 1;
        }
      },
      kurang() {
        if (!this.disabled) {
          this.jumlah = this.jumlah - 1;
          if (this.jumlah < 1) {
            this.jumlah = 1;
          }
        }
      },
      async addCart() {
        if (this.$store.state.isAuthenticated) {
          if (this.carts?.product_indent.length > 0) {
            this.$emit('alert');
          } else {
            const content = await axios.post(Api.cartUrl, {
              product_id: this.item.product_id,
              qty: this.jumlah,
            });

            // const p_exist = this.$store.state.cart.filter(
            //   (f) => f.product_id === this.item.product_id
            // );
            // let crt = [];
            // if (p_exist.length === 0) {
            //   crt = this.$store.state.cart.concat({
            //     ...content.data.data,
            //     qty: this.jumlah,
            //   });
            // } else {
            //   crt = this.$store.state.cart.map((f) => {
            //     if (f.product_id === this.item.product_id) {
            //       f.qty = f.qty + this.jumlah;
            //     }
            //     return f;
            //   });
            // }
            // this.$store.commit('setCount', crt.length);
            // this.$store.commit('setCart', {
            //   cart: crt,
            // });
            await this.$store.dispatch('getCart');
            this.$router.push('/cart');
          }
        } else {
          const redirectUrl = localStorage.setItem('redirect_url', window.location.pathname);
          this.$router.push('/login');
        }
      },
    },
    computed: {
      url() {
        return window.location.href ? window.location.href : null;
        // return `https://rifyo-stage.saga.id${this.$route.fullPath}`;
      },
      carts() {
        return this.$store.state.carts;
      },
      productStock() {
        return this.item?.product?.stock ? this.item.product.stock : 0;
      },
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
      isWishlist() {
        if (this.wishlist.indexOf(this.item?.product?.parent_id) != -1) {
          return true;
        } else {
          return false;
        }
      },
    },
    components: {
      Loading,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      stock: {
        type: Number,
        default: 0,
      },
      image_src: {
        type: String,
        default: '/playground_assets/min.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      textinput_placeholder: {
        type: String,
        default: '1',
      },
      image_src1: {
        type: String,
        default: '/playground_assets/plus.svg',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      text: {
        type: String,
        default: 'Add To Cart',
      },
      image_src2: {
        type: String,
        default: '/playground_assets/share.svg',
      },
      image_alt2: {
        type: String,
        default: 'image',
      },
      image_src3: {
        type: String,
        default: '/playground_assets/ic_heart.svg',
      },
      image_src3_active: {
        type: String,
        default: '/playground_assets/love_red.svg',
      },
      image_alt3: {
        type: String,
        default: 'image',
      },
      rootClassName: String,
      text1: {
        type: String,
        default: 'Eames Lounge Chairs & Ottoman',
      },
      text2: {
        type: String,
        default: 'IDR 1.350.000',
      },
      item: null,
    },
    watch: {
      disabled(nv, ov) {
        nv || (this.jumlah = 1);
      },
      jumlah(nv, ov) {
        if (nv > this.productStock && this.stock) {
          this.$emit('error', {
            stock: this.stock,
            jumlah: this.jumlah,
          });
        } else {
          this.$emit('error', {
            stock: this.stock,
            jumlah: this.jumlah,
          });
        }
      },
    },
  };
</script>

<style scoped>
  .box-indent-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .box-indent-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
    background-color: #fff;
  }
  .box-indent-container2 {
    flex: 0 0 auto;
    width: 100%;
    /* height: 100px; */
    margin-bottom: 16px;
    display: none;
    align-items: flex-start;
  }
  .box-indent-container3 {
    display: flex;
    align-items: center;
  }
  .box-indent-image {
    width: 24px;
    object-fit: cover;
  }
  .box-indent-textinput {
    width: 52px;
    border: 1px solid #1f252c;
    height: 44px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .box-indent-image1 {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  .box-indent-btn-login {
    flex: 0 0 auto;
    width: 50%;
    cursor: pointer;
    height: 44px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #1f252c;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2.4px;
    font-family: Montserrat, sans-serif;
    color: #fff;
  }
  .box-indent-btn-login:not(:disabled):hover,
  .box-indent-btn-login-disabled:not(:hover) {
    opacity: 0.7;
  }
  .box-indent-btn-login:disabled,
  .box-indent-btn-login-disabled {
    opacity: 0.75;
    cursor: not-allowed !important;
  }
  .box-indent-text2 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.4px;
  }
  .box-indent-container4 {
    display: flex;
    align-items: center;
  }
  .box-indent-image2 {
    width: 24x;
    object-fit: cover;
    margin-right: var(--dl-space-space-unit);
    cursor: pointer;
  }
  .box-indent-image3 {
    width: 24px;
    object-fit: cover;
    cursor: pointer;
  }

  .box-indent-image2:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .box-indent-image3:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .rootClassName {
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
  @media (max-width: 991px) {
    .box-indent-text2 {
      font-size: 16px;
    }
    .box-indent-container1 {
      flex-wrap: wrap;
      padding-top: 24px;
      /* padding-left: var(--dl-space-space-halfunit); */
      /* padding-right: var(--dl-space-space-halfunit); */
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 24px;
    }
    .box-indent-container2 {
      width: auto;
      display: flex;
      flex-basis: 70%;
      flex-direction: column;
    }
    .box-indent-text {
      font-size: 16px;
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
    }
    .box-indent-text1 {
      margin-top: var(--dl-space-space-halfunit);
      font-family: Montserrat;
    }
    .box-indent-btn-login {
      order: 4;
      font-size: 16px;
    }
    .box-indent-container4 {
      order: 2;
      flex-basis: 30%;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 34px;
      padding-right: 46px;
    }
  }
</style>
